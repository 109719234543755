@use 'sass:math';

@import '~app/mixins';

$border-color: #dfe1e4;


$label-color: #99a0aa;


.statistics {
  background-color: $color-dirty-white;

  .row {
    border-top: 0;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    position: relative;

    .title {
      text-align: center;
      padding-top: 7px;
      padding-bottom: 7px;
      z-index: 3;
      font-size: 16px;
      color: $color-teal;
    }

    .exportButton {
      cursor: pointer;
      color: $color-grey;
      position: absolute;
      right: 0;
      top: 3px;

      .iconExport {
        use {
          fill: $color-grey;
        }

        width: 22px;
        height: 21px;
      }
    }

    .item {
      border: 1px solid $border-color;
      border-top: 0;
      border-bottom-width: 2px;
      flex: auto;
      padding: 14px 15px;
      min-width: math.div(2, 3);

      &:first-child {
        border-left-width: 2px;
      }

      &:last-child {
        border-right-width: 2px;
      }

      &.longTrend {
        flex-basis: math.div(2, 3);
      }
    }

    &:last-child .item {
      border-bottom: 0;
    }

    &:first-child {
      border-bottom: 2px solid $border-color;
      border-left: 2px solid $border-color;
      border-right: 2px solid $border-color;
    }

    .trend {
      padding: 9px 15px 15px;
      display: flex;
      flex-direction: column;

      .header {
        color: $color-teal;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dropdown {
          width: auto;

          select {
            color: $color-teal;
            font-size: 12px;
            background: transparent;
            border: 0;
            width: auto;
          }
        }
      }

      .trendItems {
        flex: 1;
        display: flex;

        .trendItem {
          padding: 27px 28px 17px;

          &:first-child {
            padding-left: 5px;
          }

          &:last-child {
            padding-right: 5px;
          }
        }
      }
    }

    .chartInfo {
      display: flex;

      .statWraper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .statItem {
          width: 100%;
          display: flex;
          flex: 1;
          align-items: center;
          padding: 0 12px;

          .value {
            font-weight: 500;
            font-size: 14px;
            color: $color-grey;
          }

          .statItemValue {
            display: flex;
            align-items: center;

            .icon {
              width: 26px;
              margin-right: 10px;
              text-align: center;
              display: flex;
              align-items: center;

              .arrow {
                width: 24px;
                height: 21px;

                use {
                  fill: $color-green;
                }
              }

              .arrowDown {
                transform: rotate(180deg);

                use {
                  fill: $color-red;
                }
              }
            }
          }
        }

        .label {
          font-size: 12px;
          color: $label-color;
          line-height: 14px;
          padding-left: 7px;
        }
      }

      .hr {
        margin: 0;
        border: 1px solid $border-color;
      }
    }
  }
}
